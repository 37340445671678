<template>
<div class="blog-section home-section">
    <div class="section-wrapper">
        <h3 class="section-small-header">{{ $t('HOME.BLOG') }}</h3>
        <p class="section-title">{{ title }}</p>
        <div class="posts">
            <BlogTile v-for="post in posts" :key="post.slug" class="tile" :post="post" />
        </div>
        <NuxtLink v-t="'HOME.VISIT_BLOG'" to="/blog" class="anchor-button outline" />
    </div>
</div>
</template>

<script lang="ts">
import BlogTile from '$components/BlogPostTile.vue'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    components: {
        BlogTile
    },

    props: {
        title: String
    },

    data() {
        return {
            isError: false,
            isLoading: true,
            posts: <JBGWeb.BlogPost[] | null> null
        }
    },

    async asyncData({ $cms, $i18n }) {
        const data = {}

        try {
            data.posts = await $cms.fetchMany<JBGWeb.BlogPost[]>('blog-posts', {
                // fields: ['slug', 'title', 'publishedAt'],
                populate: ['featuredImage'],
                sort: 'publishedAt:desc',
                pagination: { limit: 4 },
                locale: $i18n.locale.value
            })
        } catch (error) {
            console.error('Failed to fetch blog posts:', error.message)
            data.isError = true
        } finally {
            data.isLoading = false
        }

        return data
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.blog-section {
    @include background-image-set('/images/homepage/blog_bg.jpg', url(/images/homepage/blog_bg.webp));
    background-repeat: no-repeat;
    background-size: cover;
}

.posts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 0 0 0 0 0;
    gap: 0 20px;
    margin-bottom: 20px;
    overflow: hidden;

    @include mq-medium-and-less {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq-xsmall {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 0 0 0 0;
    }
}

@include mq-xsmall {
    .anchor-button {
        width: 100%;
    }
    .tile {
        margin-top: 0px;
        margin: 20px;
    }
}
</style>

<template>
<div class="about-section home-section">
    <div class="section-wrapper">
        <div class="mobile-title">
            <h1 class="section-small-header">{{ $t('HOME.OFFICIAL_WEBSITE') }}</h1>
            <p class="section-title">{{ title }}</p>
        </div>
        <div ref="holder" class="mayo-holder">
            <ScrollAnimation action="slide-fade-bottom" :anchor="($refs.holder)">
                <div class="mayo" />
            </ScrollAnimation>
        </div>
        <div class="about-text">
            <div class="wide-title">
                <h1 class="section-small-header">{{ $t('HOME.OFFICIAL_WEBSITE') }}</h1>
                <p class="section-title">{{ title }}</p>
            </div>
            <div v-md="body" />
            <div class="about-grid">
                <GameTile
                    v-for="game, index in games"
                    :key="`${game.title}-${index}`"
                    :game="game"
                    class="game-tile"
                    v-on:click="navigateToGameDetails(game)"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import GameTile from '$components/GameTile.vue'
import ScrollAnimation from '$components/ScrollAnimation.vue'
import type { CMS } from '$services/cms'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    components: {
        GameTile,
        ScrollAnimation
    },

    props: {
        title: String,
        body: String,
        featured: {
            // type: Object as PropType<CMS.Content<JBGWeb.GameDetail>>
        }
    },

    computed: {
        games() {
            if (!this.featured?.data || !Array.isArray(this.featured.data)) return []
            return this.featured.data.map((entry: CMS.Data<JBGWeb.GameDetail>) => entry.attributes)
        }
    },

    methods: {
        navigateToGameDetails(game: JBGWeb.GameDetail) {
            if (!game.packDetails?.data) {
                void this.$router.push(this.localeRoute({
                    name: 'games-gameslug',
                    params: {
                        gameslug: game.slug
                    }
                }))
            } else {
                void this.$router.push({
                    name: 'games-packs-packslug',
                    params: {
                        _: game.packDetails?.data?.attributes.slug,
                        packslug: game.slug
                    }
                })
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.about-section {
    h2 {
        margin-top: 40px;
    }
}

.home-section
.section-wrapper {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    @include mq-xsmall {
        display: flex;
        flex-direction: column;
    }
}

.mayo-holder {
    grid-column: 1 / span 5;
    padding: 0 20px 0 0;
    overflow: hidden;
    @include mq-small {
        grid-column: 1 / span 4;
    }
    @include mq-xsmall {
        grid-column: unset;
        height: 300px;

    }
}

.mayo {
    width: 100%;
    height: 100%;
    @include background-image-set('/images/homepage/mayo.png', url(/images/homepage/mayo.webp));
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-position: top right;
}

.mobile-title {
    display: none;
    margin-top: 20px;
    @include mq-xsmall {
        display: block;
    }
}

.about-text {
    padding: 100px 80px;
    grid-column: 6 / span 7;
    display: inline-block;
    text-align: left;
    .wide-title {
        display: block;
    }
    @include mq-medium {
        padding: 80px 48px;
    }
    @include mq-small {
        padding: 64px 24px;
        grid-column: 5 / span 8;
    }
    @include mq-xsmall {
        padding: 10px;
        grid-column: unset;
        background: var(--surface-600);
        border-radius: 16px 16px 0 0;
        font-size: 16px;
        .wide-title {
            display: none;
        }
    }
}

.about-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    padding-bottom: 20px;

    .game-tile img {
        width: 100%;
    }

    @include mq-small-and-less {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    }
}

@include mq-medium {
    .mayo {
        background-size: 120%;
    }
}

@include mq-small {
    .about-section {
        h2 {
            margin-top: 30px;
        }
    }
}

</style>

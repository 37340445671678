<template>
<div class="carousel-item">
    <div class="feature">
        <div class="overlay">
            <NuxtLink :to="packDetailsPage">
                <img
                    src="/images/homepage/packs/jpp6-feature.png"
                    :alt="$t('HOME.TJPS_LOGO')"
                    class="logo"
                    tabindex="0"
                >
            </NuxtLink>
            <div class="flex-column cta">
                <AddToCartButton :product="product" :pack="pack" />
                <NuxtLink :to="packDetailsPage">
                    <button class="learn-more outline wide">
                        <span class="button-text">{{ $t('GAME_DETAIL.LEARN_MORE') }}</span>
                        <img
                            src="/images/icons/arrow-right.svg" alt="->" class="arrow-right"
                        >
                    </button>
                </NuxtLink>
                <StorefrontIcons
                    v-if="platformOptions && platformOptions.length > 0" :pack="pack"
                    :platform-options="platformOptions" :fell-back-to-en="true"
                />
            </div>
        </div>
        <video
            class="bg-video" poster="/images/homepage/tmp2_bg_poster.jpg" loop
            autoplay
            muted playsinline
        >
            <source src="/images/homepage/tmp2_bg_vid.webm" type="video/webm">
        </video>
    </div>
</div>
</template>

<script lang="ts">
import { I18n } from '$services/i18n'
import { getShopifyLink, getPlatformInfo } from '$services/storefronts'
import StorefrontIcons from '$components/shopping/StorefrontIcons.vue'
import AddToCartButton from '$components/shopping/AddToCartButton.vue'
import type { JBGWeb } from '$types/JBGWeb'

export default defineNuxtComponent({
    components: {
        AddToCartButton,
        StorefrontIcons
    },

    data() {
        return {
            pack: <JBGWeb.PackDetail | null> null,
            product: <JBGWeb.ProductDetail | null> null,
            getShopifyLink,
            getPlatformInfo
        }
    },

    async asyncData({ $cms, $i18n }) {
        const data = {}

        try {
            data.pack = await $cms.fetchOne<JBGWeb.PackDetail>('pack-details', 'the-jackbox-party-starter', {
                locale: $i18n.locale.value
            })
        } catch (error) {
            // TODO: remove this when we can load localized games
            if ((error as Error).message === 'No data') {
                try {
                    // force a fetch in en
                    const pack = await $cms.fetchOne<JBGWeb.PackDetail>('pack-details', 'the-jackbox-party-starter', {
                        locale: 'en'
                    })

                    data.pack = pack
                } catch (error) {
                    console.error('Failed to fetch pack details for TMP2:', error.message)
                }
            } else {
                console.error('Failed to fetch pack details for TMP2:', error.message)
            }
        }

        try {
            // get shopify product data
            const filters: Record<string, string> = { name: 'The Jackbox Party Pack 6' }
            const product = await $cms.fetchOne<JBGWeb.ProductDetail>('products', '', {
                fullResponse: true,
                filters,
                locale: $i18n.locale.value
            })
            if (!product[0].id) return console.warn('no product id')
            const productDetails = await $cms.fetchOne<JBGWeb.ProductDetail>(`products/${product[0].id}`, '' as string, {
                fullResponse: true,
                locale: $i18n.locale.value
            })

            data.product = productDetails
        } catch (error) {
            console.error('Failed to fetch product data for TMP2', error.message)
        }

        return data
    },

    computed: {
        platformOptions() {
            if (!this.product) {
                return
            }

            const platforms: JBGWeb.PlatformOption[] = []
            const shopifyData: JBGWeb.ShopifyData = this.product.shopifyData

            if (!shopifyData.data.product) {
                return
            }

            const shopifyMetafieldsArr: JBGWeb.ProductMetafield[] = shopifyData.data.product.metafields

            if (!shopifyMetafieldsArr) return console.warn('found no metafields on product')

            // for packs this should always just be an array of 1
            let fields: JBGWeb.ProductMetafieldFields[] = []
            shopifyMetafieldsArr.forEach((metafieldsObj) => {
                if (!metafieldsObj) {
                    console.warn('found no fields in metafields object')
                    return
                }
                fields = metafieldsObj.reference.fields
            })

            fields.forEach((field: JBGWeb.ProductMetafieldFields) => {
                const platform: JBGWeb.PlatformOption = {
                    text: null,
                    icon: null,
                    vendor: null,
                    price: null,
                    currencyCode: null
                }

                platform.vendor = field.key

                // destructure field.value
                // which comes in like this from shopify: "{\"amount\":\"29.99\",\"currency_code\":\"USD\"}"
                const json = JSON.parse(field.value as string)
                // do not include storefront when price is 0.0
                if (json.amount !== '0.0') {
                    platform.price = Number(json.amount)
                    platform.currencyCode = json.currency_code

                    const platformInfo = this.getPlatformInfo(field.key)
                    if (platformInfo) {
                        platform.text = platformInfo[0]
                        platform.icon = platformInfo[1]
                    }

                    platforms.push(platform)
                }
            })

            return platforms
        },

        packDetailsPage() {
            let slug = 'the-jackbox-party-pack-6'
            if (I18n.locale !== 'en') {
                slug = `the-jackbox-party-pack-6-${this.$i18n.locale.toLowerCase()}`
            }

            return this.localeRoute({
                name: 'games-packs-packslug',
                params: {
                    packslug: slug
                }
            })
        }
    },

    methods: {
        navigateToSteam() {
            window.location.href = 'https://store.steampowered.com/app/1755580/The_Jackbox_Party_Starter/'
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

$overlayGradient: linear-gradient(90deg,
    rgba(0,0,0,.8) 0%,
    rgba(0,0,0,0.6) 80%,
    rgba(0,0,0,0) 100%);

.feature {
    position: relative;
    height: 100%;
    .bg-video {
        top: 0;
        position: absolute;
        width: 100%;
        @include mq-xsmall {
            display: none;
        }
    }
    @include mq-xsmall {
        background-image: url('/images/homepage/pp6-carousel-slide-mobile-YES-gradient.jpg');
        background:
            linear-gradient(
                0deg, rgba(0, 0, 0, 0.64) 0%,
                rgba(0, 0, 0, 0.48) 26.95%,
                rgba(0, 0, 0, 0.19) 45.55%,
                rgba(0, 0, 0, 0.00) 100%
            ),
            image-set(url(/images/homepage/pp6-carousel-slide-mobile-YES-gradient.jpg));
        background-size: cover;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: end;
        padding-bottom: 16px;
    }
    .overlay-container {
        position: relative;
        z-index: 10;
        height: 100%;
        border: 10px solid #00ffff;
    }
    .overlay {
        position: relative;
        height: 100%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 80px;
        max-width: 450px;
        background: $overlayGradient;
        @include mq-medium {
            max-width: 400px;
        }
        @include mq-small {
            padding: 0 20px;
            max-width: 350px;
        }
        @include mq-xsmall {
            background: none;
            justify-content: flex-end;
        }

        .logo {
            padding: 0 20px;
            width: 100%;
            max-width: 360px;
            height: auto;
            @include mq-medium {
                padding: 0 40px;
                max-width: 300px;
            }
            @include mq-small {
                padding: 0 40px;
                max-width: 240px;
            }
            @include mq-xsmall {
                height: 140px;
                width: auto;
            }
        }

        button {
            cursor: pointer;

            &.learn-more {
                font-size: 1em;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover {
                    @include mq-small-and-less {
                        padding: 11px;
                    }
                }

                .arrow-right {
                    height: 14px;
                }
                @include mq-small-and-less {
                    padding: 12px;
                }
            }
        }
    }
}

.logo {
    cursor: pointer;
    padding: 8px;

    &:hover {
        transition: transform .2s;
        transform: scale(1.05, 1.05);
    }
}

</style>

<template>
<div class="carousel-item">
    <div class="megapicker-feature" :class="bg">
        <div class="overlay">
            <NuxtLink :to="localeRoute({ name: 'the-jackbox-megapicker' })">
                <img
                    src="/images/homepage/megapicker-logo.svg" alt="The Jackbox Megapicker" class="wordmark"
                    tabIndex="0" role="button"
                >
            </NuxtLink>
            <div class="notes">
                <div class="steam-users">
                    <img class="logo" src="/images/icons/steam-icon.svg" alt="Steam">
                    <p>For <b>Steam</b> Users</p>
                </div>
                <p class="free">{{ $t('CTA.FREE') }}</p>
            </div>
            <a href="https://store.steampowered.com/app/2828500/The_Jackbox_Megapicker/" target="_blank">
                <button class="primary wide icon-button">
                    <p>{{ $t('CTA.VIEW_ON_STEAM') }}</p>
                    <div class="icons">
                        <img src="/images/icons/windows.svg" alt="Windows" class="windows">
                        <img src="/images/icons/apple.svg" alt="Mac" class="apple">
                        <img src="/images/icons/linux.svg" alt="Linux" class="linux">
                    </div>
                </button>
            </a>
            <NuxtLink :to="localeRoute({ name: 'the-jackbox-megapicker' })">
                <button class="outline wide icon-button">
                    <p>{{ $t('GAME_DETAIL.LEARN_MORE') }}</p>
                    <div class="icons">
                        <img src="/images/icons/arrow-right.svg" alt="->" class="arrow-right">
                    </div>
                </button>
            </NuxtLink>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        bg: {
            type: String,
            default: ''
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/kit.scss";

.megapicker-feature {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('/images/homepage/megapicker-feature-bg.jpg');
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 26.97%, rgba(0, 0, 0, 0.12) 45.55%, rgba(0, 0, 0, 0.00) 100%),
        image-set(url(/images/homepage/megapicker-feature-bg.webp));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    &.alt {
        background-image: url('/images/homepage/megapicker-feature-bg-alt.jpg');
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 26.97%, rgba(0, 0, 0, 0.12) 45.55%, rgba(0, 0, 0, 0.00) 100%),
            image-set(url(/images/homepage/megapicker-feature-bg-alt.webp));
        background-size: cover;
    }
    @include mq-xsmall {
        background-image: url('/images/homepage/megapicker-feature-mobile-bg.jpg');
        background:
            linear-gradient(
                0deg, rgba(0, 0, 0, 0.64) 0%,
                rgba(0, 0, 0, 0.48) 26.95%,
                rgba(0, 0, 0, 0.19) 45.55%,
                rgba(0, 0, 0, 0.00) 100%
            ),
            image-set(url(/images/homepage/megapicker-feature-mobile-bg.webp));
        &.alt {
            background-image: url('/images/homepage/megapicker-feature-mobile-bg-alt.jpg');
            background:
                linear-gradient(
                    0deg, rgba(0, 0, 0, 0.64) 0%,
                    rgba(0, 0, 0, 0.48) 26.95%,
                    rgba(0, 0, 0, 0.19) 45.55%,
                    rgba(0, 0, 0, 0.00) 100%
                ),
                image-set(url(/images/homepage/megapicker-feature-mobile-bg-alt.webp)
            );
            background-repeat: no-repeat;
            background-size: cover;
        }
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        align-items: end;
        justify-content: center;
        padding-bottom: 16px;
    }


    .overlay {
        margin: 0 80px;
        max-width: 320px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include mq-medium {
            max-width: 280px;
        }
        @include mq-small {
            margin: auto 40px;
            max-width: 320px;
            min-width: 220px;
            width: 35vw;
        }
        @include mq-xsmall {
            flex-direction: column;
            gap: 8px;
            margin: 0;
            max-width: 300px;
            padding: 16px;
            width: 100%;
        }

        .wordmark {
            margin: auto auto;
            transition: transform .2s;
            cursor: pointer;

            &:hover {
                transform: scale(1.10)
            }

            @include mq-small {
                max-width: 190px;
            }
            @include mq-xsmall {
                max-width: 207px;
            }
        }

        .logo {
            width: 100%;
            height: auto;
        }

        .notes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            @include mq-small-and-less {
                font-size: 10px;
            }
            .steam-users {
                display: flex;
                align-items: center;
                gap: 5px;
                img {
                    height: 14px;
                    width: 14px;
                }
                p {
                    text-transform: uppercase;
                    margin: 0;
                }
            }
            .free {
                display: flex;
                align-items: center;
                margin: 0;
                font-style: italic;
                font-weight: 800;
                text-transform: uppercase;
            }
        }

        button {
            height: 48px;
            font-size: 16px;
            line-height: 16px;
            text-align: left;
            margin: 0;
            background: rgba(0, 0, 0, 0.40);
            cursor: pointer;
            &.primary {
                background: linear-gradient(90deg, #FAD000 0%, #FFF478 100%);
                font-weight: 800;
                text-transform: uppercase;

                &:hover {
                    background: linear-gradient(90deg, #f8e272 0%, #f5efae 100%);
                    box-shadow: 0px 0px 27.901px 0px #FFD816, 0px 0px 1.329px 0px #FFD816, 0px 0px 0.664px 0px #FFD816;
                }
            }
            &.outline {
                &:hover {
                    border: 2px solid #FFE461;
                    color: #FFE461;
                    .arrow-right {
                        filter: sepia(95%) saturate(1011%) hue-rotate(352deg) brightness(100%) contrast(80%);
                    }
                }
            }
            @include mq-small-and-less {
                height: 38px;
                font-size: 14px;
                line-height: 14px;
            }
        }
        .icon-button {
            align-items: center;
            display: flex;
            justify-content: space-between;
            p {
                margin: 0;
                @include mq-small{
                    margin-right: 8px;
                }
            }
            .icons {
                display: flex;
                gap: 8px;
                align-items: center;
                img {
                    &.windows{
                        height: 16px;
                        width: 17px;
                    }
                    &.apple {
                        height: 16px;
                        width: 14px;
                    }
                    &.linux {
                        height: 18px;
                        width: 15px;
                    }
                    &.arrow-right {
                        height: 18px;
                        width: 15.429px;
                    }

                    @include mq-small-and-less {
                        &.windows {
                            width: 15px;
                            height: 14px;
                        }
                        &.apple {
                            width: 12px;
                            height: 14px;
                        }
                        &.linux {
                            height: 16px;
                            width: 13px;
                        }
                        &.arrow-right {
                            height: 16px;
                            width: 13.715px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
<div class="faq-entry">
    <div class="question" v-on:click="toggle" v-on:keydown.enter="toggle">
        <div>{{ question }}</div>
        <div class="toggle" :class="{rotated: isOpen}">
            <img
                class="fa fa-regular fa-plus"
                alt=""
                aria-hidden
                focusable="false"
            >
        </div>
    </div>
    <Transition>
        <div v-show="isOpen" ref="answer" v-md="answer" class="answer" />
    </Transition>
</div>
</template>

<script lang="ts">
export default defineNuxtComponent({
    props: {
        question: String,
        answer: String
    },
    data() {
        return {
            isOpen: false,
            rippleList: [] as any[]
        }
    },
    methods: {
        toggle() {
            this.isOpen ? this.close() : this.open()
        },
        open() {
            this.isOpen = true
        },
        close() {
            this.isOpen = false
        }
    }
})

</script>

<style lang="scss" scoped>

.question {
    display: flex;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--tinted-neutral-500, #64748B);
    background: var(--surface-50, #3A3C43);
    cursor: pointer;
    transition-duration: 0.1s;
    &:hover {
        border: 1px solid var(--tinted-neutral-50, #64748B);
        background: var(--surface-400, #3A3C43);
    }
    .toggle {
        transition: all 0.3s ease;
        &.rotated {
            transform: rotate(45deg);
        }
    }
}
.answer {
    padding-top: 15px;
    &.open {
        display: block;
    }
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
